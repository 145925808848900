<template>
  <div class="page">
    <div class="release">
      <div class="login_top">
        <!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
        <!-- <div class="login_title">{{$t('my.release')}}</div> -->
      </div>
      <div class="release_input">
        <div class="release_input_top">
          <div class="release_input_left">
            <div class="login_title">{{ $t("my.release") }}</div>

            <!-- 标题 -->
            <div class="input_item">
              <div class="input_title">{{ $t("release.title") }}</div>
              <div class="input_content">
                <el-input
                  type="text"
                  style="width: 856px"
                  :placeholder="$t('release.inputTitle')"
                  v-model="title"
                />
              </div>
            </div>

            <!-- 类别 -->
            <div class="input_item">
              <div class="input_title">{{ $t("release.type") }}</div>
              <div class="input_content">
                <el-cascader
                  style="width: 856px"
                  @change="cascaderChangeClick($event, 1)"
                  clearable
                  v-model="cate_name"
                  :options="classifyList"
                  :placeholder="$t('release.selectType')"
                  :disabled="showDis"
                >
                </el-cascader>
              </div>
            </div>

            <!-- 所在城市 -->
            <div class="input_item">
              <div class="input_title">{{ $t("release.cityLive") }}</div>
              <div class="input_content">
                <el-cascader
                  style="width: 856px"
                  @change="cascaderChangeClick($event, 2)"
                  clearable
                  v-model="area_name"
                  :options="countryList"
                  :placeholder="$t('other.selectCity')"
                  :disabled="showDis"
                >
                </el-cascader>
              </div>
            </div>

            <!-- 详细位置 v-if="isHasAddress"-->
            <!-- :disabled="true" -->

            <template>
              <div class="input_item">
                <div class="input_title">{{ $t("detailAddress") }}</div>
                <div class="input_content">
                  <el-input
                    style="width: 856px"
                    type="text"
                    :placeholder="$t('InputDetailAddress')"
                    v-model="address"
                  />
                </div>
              </div>
                    <!-- :disabled="true" -->

              <!-- <google-map ref="google-map" /> -->

              <div
                class="address"
                style="width: 800px; height: 400px; margin-bottom: 24px"
              >
                <mapComponent
                  @getAddressEvent="getAddressEvent"
                  @location="getlocation"
                ></mapComponent>
              </div>
            </template>

            <!-- 发布内容 -->
            <div class="input_item">
              <div class="input_title">{{ $t("releaseContent") }}</div>
              <div class="input_textarea" @click="textareaClick">
                <el-input
                  v-model="detail"
                  type="textarea"
                  :autosize="true"
                  style="width: 800px"
                  :placeholder="$t('release.pleaseEnterDescriptionDetails')"
                  ref="textareaRef"
                >
                </el-input>
              </div>
            </div>

            <div class="input_item">
              <!-- <div class="input_title">{{ $t("releaseContent") }}</div> -->

              <div class="video">
                <el-upload
                  ref="uploadVideo"
                  :data="{
                    dir: 'file',
                  }"
                  :action="uploadImgUrl"
                  list-type="picture-card"
                  :limit="1"
                  :before-upload="beforeUpload2"
                  :on-success="onSuccess"
                  :show-file-list="false"
                  :on-progress="progressing"
                >
                  <span class="el-upload-action"></span>
                  <i class="el-icon-plus" v-show="!videoUrl"></i>
                  <div class="upload_video" v-show="videoUrl">
                    <video
                      controls="controls"
                      :src="videoUrl"
                      class="video"
                    ></video>
                    <i class="el-icon-remove" @click.stop="removeVideo"></i>
                  </div>
                  <div slot="tip" class="el-upload__tip">
                    {{ $t("other.uploadVideo") }} ({{ $t("videoMessage") }})
                  </div>
                </el-upload>
                <!-- 
                <el-upload
                  ref="uploadImg"
                  :action="uploadImgUrl"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :limit="1"
                  :before-upload="beforeUpload1"
                  :on-change="onChange"
                >
                  <i class="el-icon-plus"></i>
                  <div
                    slot="tip"
                    class="el-upload__tip"
                    style="white-space: nowrap"
                  >
               
                  </div>
                </el-upload> -->
              </div>
              <div class="all_imgs">
                <el-upload
                  ref="uploadImg"
                  :action="uploadImgUrl"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :limit="25"
                  :before-upload="beforeUpload"
                  :on-change="onChange"
                  :multiple="true"
                  :file-list="previewFileList"
                >
                  <i class="el-icon-plus"></i>
                  <div
                    slot="tip"
                    class="el-upload__tip"
                    style="white-space: nowrap"
                  >
                    {{ $t("other.uploadPhoto") }} ({{ $t("home.atMost") }} 25
                    {{ $t("home.open") }}.{{ $t("imgMessage") }})
                  </div>
                </el-upload>
                <el-dialog :visible.sync="previewImgDialog" :modal="false">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
            </div>
          </div>

          <!-- 联络信息 -->
          <div class="release_input_right">
            <div class="login_title">{{ $t("contactInfo") }}</div>

            <!-- 联系人 -->
            <div class="input_item">
              <div class="input_title">{{ $t("contacts") }}</div>
              <div class="input_content">
                <el-input
                  type="text"
                  style="width: 856px"
                  :placeholder="$t('inputContacts')"
                  v-model="contacts"
                />
              </div>
            </div>

            <!-- 联系电话 -->
            <div class="input_item">
              <div class="input_title">{{ $t("contactsPhone") }}</div>
              <div class="input_content">
                <el-input
                  type="text"
                  style="width: 856px"
                  :placeholder="$t('inputContactsPhone')"
                  v-model="contactsPhone"
                />
              </div>
            </div>

            <!-- 邮件 -->
            <div class="input_item">
              <div class="input_title">{{ $t("email") }}</div>
              <div class="input_content">
                <el-input
                  type="text"
                  style="width: 856px"
                  :placeholder="$t('inputEmail')"
                  v-model="email"
                />
              </div>
            </div>

            <!-- WhatsApp -->
            <div class="input_item">
              <div class="input_title">WhatsApp</div>
              <div class="input_content">
                <el-input
                  type="text"
                  style="width: 856px"
                  :placeholder="$t('inputWhatsApp')"
                  v-model="WhatsApp"
                />
              </div>
            </div>

            <!-- 微信 -->
            <div class="input_item">
              <div class="input_title">{{ $t("wechatID") }}</div>
              <div class="input_content">
                <el-input
                  type="text"
                  style="width: 856px"
                  :placeholder="$t('inputWechat')"
                  v-model="wechat"
                />
              </div>
            </div>

            <!-- 竟位 -->
            <div class="input_item" v-if="!information_id">
              <div class="input_title">{{ $t("release.JinWei") }}</div>
              <el-switch
                v-model="is_hot_value"
                active-color="#F39800"
                @change="changeStatus"
              >
              </el-switch>
              <span style="color: #e60012; font-size: 16px; margin-left: 5px"
                >${{ JWMoney.hot_price }}</span
              >
            </div>

            <!-- 竟位提示 -->
            <div class="tip" v-if="!information_id">
              <img src="@/assets/mycenter/jinwei.png" alt="" />
              <span>
                {{ $t("jingweitishi") }}
              </span>
            </div>

            <div class="input_item" v-if="information_id">
              <div class="input_title">
                {{ $t("remainingTime") }}：
                <span style="color: #e60012">{{ end_time }}</span>
              </div>
            </div>

            <div class="input_item" v-else>
              <div class="input_title">
                {{ $t("infoEffectiveTime") }}：
                <span style="color: #e60012"
                  >{{ duration }}{{ $t("day") }}</span
                >
              </div>
            </div>
            <!-- 价格 -->
            <div class="input_item" v-if="!information_id">
              <div class="input_title">
                {{ $t("releasePrice") }}：
                <span style="color: #e60012" v-if="is_hot_value"
                  >${{ JWMoney.price }}</span
                >
                <span style="color: #e60012" v-else
                  >${{ noJWMoney.price }}</span
                >
              </div>
            </div>
            <!-- 税费 -->
            <div class="input_item" v-if="!information_id">
              <div class="input_title">
                {{ $t("pay.taxRate") }}：
                <span style="color: #e60012" v-if="is_hot_value"
                  >${{ JWMoney.tax_money }}</span
                >
                <span style="color: #e60012" v-else
                  >${{ noJWMoney.tax_money }}</span
                >
              </div>
            </div>
            <!-- 价格 -->
            <div class="input_item" v-if="!information_id">
              <div class="input_title">
                {{ $t("totalPrice") }}：
                <span style="color: #e60012" v-if="is_hot_value"
                  >${{ JWMoney.total_money }}</span
                >
                <span style="color: #e60012" v-else
                  >${{ noJWMoney.total_money }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- 基本信息 -->
        <div class="login_title_info">
          {{ $t("basicInfo") }}
          <button
            class="btn"
            @click="addMore"
            style="margin-right: 10px"
            v-if="pid == 159"
          >
            +
          </button>
          <button class="btn" @click="delMore" v-if="pid == 159">-</button>
        </div>

        <div v-if="pid != 159">
          <div class="basic_infor">
            <div
              class="basic_item"
              v-for="(item, index) in inforData"
              :key="index"
            >
              <div class="basic_name" v-if="item.type != 2">
                {{ lang == 'zh'? item.type_name : item.bnm_name }}
              </div>

              <!-- input框 -->
              <div class="basic_input" v-if="item.type == 1">
                <el-input
                  :type="item.is_number == 0 ? 'text' : 'number'"
                  v-model="item.value"
                  :placeholder="$t('release.placeholderYoushi')"
                  clearable
                  v-if="item.type_name == '优势'"
                />

                <el-input
                  :type="item.is_number == 0 ? 'text' : 'number'"
                  v-model="item.value"
                  :placeholder="
                    $t('release.pleaseInput') + '\u00a0' + 
                    (lang == 'zh' ? item.type_name : item.bnm_name)
                  "
                  clearable
                  v-else
                />
              </div>

              <!-- 下拉选择框 -->
              <div class="basic_input" v-if="item.type == 3">
                <el-select
                  clearable
                  v-model="item.value"
                  :placeholder="
                    $t('release.pleaseSelect') + '\u00a0' + 
                    (lang == 'zh' ? item.type_name : item.bnm_name)
                  "
                >
                  <el-option
                    v-for="(item2, index2) in item.type_data"
                    :key="index2"
                    :label="item2.name"
                    :value="item2.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

         
        </div>
        <div v-else>
          <div class="basic_infor">
            <div
              class="basic_item"
              v-for="(item, index) in inforData[0]"
              :key="index + 's'"
            >
              <div class="basic_name" v-if="item.type != 2">
                {{(lang == 'zh' ? item.type_name : item.bnm_name)}}
              </div>

              <!-- input框 -->
              <div class="basic_input" v-if="item.type == 1">
                <el-input
                  :type="item.is_number == 0 ? 'text' : 'number'"
                  v-model="item.value"
                  :placeholder="$t('release.placeholderYoushi')"
                  clearable
                  v-if="item.type_name == '优势' || 'Beneficios'"
                />

                <el-input
                  :type="item.is_number == 0 ? 'text' : 'number'"
                  v-model="item.value"
                  :placeholder="$t('release.pleaseInput') + '\u00a0' + (lang == 'zh' ? item.type_name : item.bnm_name)"
                  clearable
                  v-else
                />
              </div>

              <!-- 下拉选择框 -->
              <div class="basic_input" v-if="item.type == 3">
                <el-select
                  clearable
                  v-model="item.value"
                  :placeholder="$t('release.pleaseSelect') +  '\u00a0' + (lang == 'zh' ? item.type_name : item.bnm_name)"
                >
                  <el-option
                    v-for="(item2, index2) in item.type_data"
                    :key="index2"
                    :label="item2.name"
                    :value="item2.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div
              class="basic_item"
              v-for="(item, index1) in inforData[1]"
              :key="index1"
            >
              <div class="basic_name" v-if="item.type != 2">
                {{ lang == 'zh' ? item.type_name : item.bnm_name }}
              </div>

              <!-- input框 -->
              <div class="basic_input" v-if="item.type == 1">
                <el-input
                  :type="item.is_number == 0 ? 'text' : 'number'"
                  v-model="item.value"
                  :placeholder="$t('release.pleaseInput') +'\u00a0' +  (lang == 'zh' ? item.type_name : item.bnm_name)"
                  clearable
                />
              </div>

              <!-- 下拉选择框 -->
              <div class="basic_input" v-if="item.type == 3">
                <el-select
                  clearable
                  v-model="item.value"
                  :placeholder="$t('release.pleaseSelect') +'\u00a0' +  (lang == 'zh' ? item.type_name : item.bnm_name)"
                >
                  <el-option
                    v-for="(item2, index3) in item.type_data"
                    :key="index3"
                    :label="item2.name"
                    :value="item2.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div v-if="ModelNum >= 2">
            <div
              style="padding-left: 8px; margin-bottom: 10px; font-size: 16px"
            >
              {{ $t("home.model") }} B
            </div>
            <div class="basic_infor">
              <div
                class="basic_item"
                v-for="(item, index) in inforData[2]"
                :key="index + 's'"
              >
                <div class="basic_name" v-if="item.type != 2">
                  {{ lang == 'zh' ? item.type_name : item.bnm_name }}
                </div>

                <!-- input框 -->
                <div class="basic_input" v-if="item.type == 1">
                  <el-input
                    :type="item.is_number == 0 ? 'text' : 'number'"
                    v-model="item.value"
                    :placeholder="$t('release.pleaseInput') +'\u00a0' +  (lang == 'zh' ? item.type_name : item.bnm_name)"
                    clearable
                  />
                </div>

                <!-- 下拉选择框 -->
                <div class="basic_input" v-if="item.type == 3">
                  <el-select
                    clearable
                    v-model="item.value"
                    :placeholder="$t('release.pleaseSelect') +'\u00a0' +  (lang == 'zh' ? item.type_name : item.bnm_name)"
                  >
                    <el-option
                      v-for="(item2, index2) in item.type_data"
                      :key="index2"
                      :label="item2.name"
                      :value="item2.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="ModelNum >= 3">
            <div
              style="padding-left: 8px; margin-bottom: 10px; font-size: 16px"
            >
              {{ $t("home.model") }} C
            </div>
            <div class="basic_infor">
              <div
                class="basic_item"
                v-for="(item, index) in inforData[3]"
                :key="index + 's'"
              >
                <div class="basic_name" v-if="item.type != 2">
                  {{ lang == 'zh' ? item.type_name : item.bnm_name }}
                </div>

                <!-- input框 -->
                <div class="basic_input" v-if="item.type == 1">
              

                  <el-input
                    :type="item.is_number == 0 ? 'text' : 'number'"
                    v-model="item.value"
                    :placeholder="$t('release.pleaseInput') +'\u00a0' + (lang == 'zh' ? item.type_name : item.bnm_name)"
                    clearable
                  />
                </div>

                <!-- 下拉选择框 -->
                <div class="basic_input" v-if="item.type == 3">
                  <el-select
                    clearable
                    v-model="item.value"
                    :placeholder="$t('release.pleaseSelect') + '\u00a0' +(lang == 'zh' ? item.type_name : item.bnm_name)"
                  >
                    <el-option
                      v-for="(item2, index2) in item.type_data"
                      :key="index2"
                      :label="item2.name"
                      :value="item2.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="ModelNum >= 4">
            <div
              style="padding-left: 8px; margin-bottom: 10px; font-size: 16px"
            >
              {{ $t("home.model") }} D
            </div>
            <div class="basic_infor">
              <div
                class="basic_item"
                v-for="(item, index) in inforData[4]"
                :key="index + 's'"
              >
                <div class="basic_name" v-if="item.type != 2">
                  {{ lang == 'zh' ? item.type_name : item.bnm_name }}
                </div>

                <!-- input框 -->
                <div class="basic_input" v-if="item.type == 1">
                 

                  <el-input
                    :type="item.is_number == 0 ? 'text' : 'number'"
                    v-model="item.value"
                    :placeholder="$t('release.pleaseInput') +'\u00a0' + (lang == 'zh' ? item.type_name : item.bnm_name)"
                    clearable
                  />
                </div>

                <!-- 下拉选择框 -->
                <div class="basic_input" v-if="item.type == 3">
                  <el-select
                    clearable
                    v-model="item.value"
                    :placeholder="$t('release.pleaseSelect') + '\u00a0' +(lang == 'zh' ? item.type_name : item.bnm_name)"
                  >
                    <el-option
                      v-for="(item2, index2) in item.type_data"
                      :key="index2"
                      :label="item2.name"
                      :value="item2.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 发布按钮 -->
        <div class="release_bottom">
          <el-button
            class="release_btn"
            @click="toReleaseClick"
            :loading="loading"
          >
            {{ $t("release.immediatelyRelease") }}
            <div class="total" v-if="is_hot_status == 1">
              {{ $t("release.total") }} (${{ noJWMoney.total_money }})
            </div>
            <div class="total" v-else>
              {{ $t("release.total") }} (${{ JWMoney.total_money }})
            </div>
          </el-button>
          <div class="release_tip">
            <!-- @click="isRead = !isRead" -->
            <!-- <div class="img">
              <img src="../../assets/new_login/check.png" v-if="isRead" />
              <img src="../../assets/login/un_select.png" v-else />
            </div> -->
            <div>
              {{ $t("release.haveReadAndAgree")
              }}<span @click.stop="dialogVisible = true"
                >《{{ $t("release.PanamaAgreement") }}》</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 巴拿马协议 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="38%">
      <div slot="title">
        <div class="title_all">
          <div>{{ $t("publishingProtocol") }}</div>
        </div>
      </div>
      <div class="content" v-html="content"></div>
    </el-dialog>

    <!-- 发布成功弹窗 -->
    <div class="success_dialog">
      <el-dialog title="" :visible.sync="releaseSuccessVisible" width="30%">
        <div class="release_success">
          <div class="success_img">
            <img src="@/assets/mycenter/release_success.png" />
          </div>
          <div class="success_title">
            {{ $t("release.submitSuccess") }}
          </div>
          <div class="success_tip">{{ $t("release.waitPatient") }}</div>
        </div>
      </el-dialog>
    </div>

    <!-- 支付选择框 -->
    <div class="pay_dialog">
      <el-dialog :title="$t('my.selectPayWay')" :visible.sync="payVisible">
        <div class="pay_mongy">
          <div>
            {{ $t("pay.amountPayable") }}：<span
              class="money"
              v-if="is_hot_value"
              >${{ JWMoney.total_money }}</span
            ><span class="money" v-else>${{ noJWMoney.total_money }}</span>
          </div>
          <div class="shui" v-if="is_hot_value">
            <!-- （{{ $t("pay.include") }}{{ JWMoney.pay_tax
            }}{{ $t("pay.taxRate") }}） -->

						（{{ $t('pay.taxRate') }} {{ JWMoney.pay_tax }}% {{ $t('pay.include') }}）

          </div>
          <div class="shui" v-else>
            <!-- （{{ $t("pay.include") }}{{ noJWMoney.pay_tax
            }}{{ $t("pay.taxRate") }}） -->

						（{{ $t('pay.taxRate') }} {{ noJWMoney.pay_tax }}% {{ $t('pay.include') }}）

          </div>
        </div>

        <div class="change_pay">
          <div
            class="pay_box"
            v-for="(item, index) in payList"
            :key="index"
            @click="choosePay(item, index)"
          >
            <div class="left">
              <img :src="item.icon" alt="" />
              <div>{{ item.pay_type }}</div>
            </div>
            <div class="right">
              <img src="@/assets/mycenter/is_ok.png" v-if="active == index" />
              <img src="@/assets/mycenter/no_ok.png" v-else />
            </div>
          </div>
        </div>

        <div class="btn_pay">
          <button @click="pay_btn">
            {{ $t("pay.payNow") }}
          </button>
        </div>
      </el-dialog>
    </div>

    <!-- 视频上传进度 -->
    <div>
      <el-dialog
        :visible.sync="videoVisible"
        style="width: 600px; margin: 0 auto"
      >
        <el-progress type="circle" :percentage="percent"></el-progress>
        <div>{{ $t("percent") }}</div>
      </el-dialog>
    </div>

    <!-- <div class="success_dialog">
			<el-dialog title="" :visible.sync="releaseSuccessVisible" width="30%">
				<div class="release_success">

					<div class="success_title">
						{{ $t('release.submitSuccess') }}
					</div>
					<div class="success_tip">{{ $t('release.waitPatient') }}</div>
				</div>
			</el-dialog>
		</div> -->

    <!-- 位置凸显弹框 -->
    <!-- <el-dialog title="" :visible.sync="showStyleBackgroundVisible" width="38%">
			<div slot="title">
				<div class="title_all">

					<div>{{ $t('title.detail') }}</div>
				</div>
			</div>
			<div class="content" v-html="content">

			</div>
		</el-dialog> -->

    <!-- 位置推前弹框 -->
    <!-- <el-dialog title="" :visible.sync="showStyleHotVisible" width="38%">
			<div slot="title">
				<div class="title_all">

					<div>{{ $t('title.detail') }}</div>
				</div>
			</div>
			<div class="content" v-html="content">

			</div>
		</el-dialog> -->
  </div>
</template>

<script>
import { uploadImgUrl } from "@/common/utils/config.js";
import mapComponent from "@/components/map.vue";

export default {
  components: {
    mapComponent,
  },
  data() {
    return {
      information_id: "", // 信息id
      showStyleBackgroundVisible: false, //位置凸显的弹框
      showStyleHotVisible: false, //位置推前的弹框

      uploadImgUrl: uploadImgUrl,
      releaseSuccessVisible: false, //发布协成功弹框
      dialogVisible: false, //发布协议弹框
      dialogImageUrl: "", //
      previewImgDialog: false, //预览图片

      countryList: [], //省市区列表
      classifyList: [], //所有分类列表

      province_id: "", //城市父id
      province_name: "", //城市父名字
      addr_id: "", //城市子id
      add_name: "", //城市子名字
      area_id: "", //第三级id
      area_name: "", //第三级名称
      parent_cate_id: "", //父级分类的id
      cate_id: "", //分类id，
      cate_name: "", //分类名称

      title: "", //标题
      detail: "", //详情描述
      isRead: false, //是否阅读协议
      content: "",

      inforData: [], //获取所有信息
      isHasAddress: false, //是否含有地址
      address: "", //地址
      isShowVideo: false, //是否展示视频

      img_list: [], //上传图片
      video_List: [], //上传视频

      videoUrl: "", //视频地址
      videoId: "", //视频id

      bannerImgUrl: "",
      bannerImgId: "",

      isFlag: false,
      lang: "",

      userInfo: "", //个人信息

      is_background: false, //位置凸显
      is_hot: false, //位置推前

      back_price: "", //位置凸显价格
      hot_price: "", //位置推前价格
      info_price: "", //一条信息价格

      vip: "", //是否vip
      information_config: "", //是否免费发布信息0：不需要1：需要
      loading: false, //是否提交

      contacts: "", // 联系人
      contactsPhone: "", // 联系电话
      email: "", //邮箱
      WhatsApp: "", //WhatsApp
      wechat: "", //微信

      duration: "1",
      price: "0",
      total_money: "0",
      pay_tax: "0%", //税率
      tax_money: "", // 税后费用

      payVisible: false, //支付方式弹窗
      order_sn: "", // 支付订单
      payList: [], // 支付方式列表
      active: "0",
      pay_type: "Yappy",
      end_status: "1", //信息是否过期
      end_time: "", //过期时间

      showDis: false,

      previewFileList: [],

      lat: "39.899",
      lng: "116.39742",
      location: "",
      pid: "", // 判断新楼盘

      is_hot_value: false, // 是否竟位
      is_hot_status: 1, // 是否竟位
      JWMoney: [],
      noJWMoney: [],
      ModelNum: "1",

      user_id: "", //用户id

      videoVisible: false,
      percent: 0,
    };
  },

  created() {
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    console.log(userinfo, 123);
    if (userinfo) {
      this.user_id = userinfo.id;
    }
  },
  computed: {
    payable_money() {
      console.log("哼哼哈嘿胡");
      // 判断是否是会员
      console.log(this.information_config);
      if (this.information_config === 0) {
        console.log("---------------------");
        this.info_price = 0;
      }
      if (this.vip == 0) {
        if (this.is_background && !this.is_hot) {
          return this.back_price * 1 + this.info_price * 1;
        } else if (!this.is_background && this.is_hot) {
          return this.hot_price * 1 + this.info_price * 1;
        } else if (!this.is_background && !this.is_hot) {
          return this.info_price * 1;
        } else {
          return this.back_price * 1 + this.hot_price * 1 + this.info_price * 1;
        }
      } else {
        if (this.is_background && !this.is_hot) {
          return this.back_price * 1;
        } else if (!this.is_background && this.is_hot) {
          return this.hot_price * 1;
        } else if (!this.is_background && !this.is_hot) {
          return 0;
        } else {
          return this.back_price * 1 + this.hot_price * 1;
        }
      }
    },
  },

  watch: {
    lang(newval) {
      if (newval) {
        console.log(newval);
        this.getInformationDetail();
      }
    },
  },

  mounted() {
    this.lang = localStorage.getItem("lang");
    this.getCountry();
    this.getCategoryList();
    this.getAll();
    // this.getPrice();
    this.getPayFn();

    this.information_id = this.$route.query.information_id;
    this.end_status = this.$route.query.end_status;
    this.end_time = this.$route.query.end_time;
    this.getInformationDetail();

    if(!this.information_id){
      this.getUserInfo();
    }

    // 监听localstorage里面的数据
    window.addEventListener("setItemEvent", (e) => {
      if (e.key === "lang") {
        this.lang = e.newValue;
        this.getAll();
        this.getCategoryList();
      }
    });
  },

  methods: {
    changeStatus(e) {
      console.log(e);
      if (e) {
        this.is_hot_status = 2;
      } else {
        this.is_hot_status = 1;
      }

      console.log(this.is_hot_status);
    },
    getlocation(val) {
      this.location = val;
      console.log(this.location, 1234);
      this.lat = this.location.lat;
      this.lng = this.location.lng;
    },
    // lat(val){
    //   this.lat = val
    // },
    // 获取价格
    // getPrice() {
    // 	this.$http.getPrice().then(res => {
    // 		if (res.code == 1) {
    // 			this.back_price = res.data.back_price;
    // 			this.hot_price = res.data.hot_price;
    // 			this.info_price = res.data.info_price;
    // 			console.log("获取的价格", this.info_price);
    // 		} else {
    // 			this.$message.error(res.msg);
    // 		}
    // 	})
    // },

    // 获取支付方式
    getPayFn() {
      console.log(this.user_id);
      this.$http.changePay(
        {
          user_id:this.user_id
        }
      ).then((res) => {
        if (res.code == 1) {
          this.payList = res.data;
        } else {
          this.$message.info(res.data.msg);
        }
      });
    },
    // 选择支付方式
    choosePay(item, index) {
      console.log(item);
      this.pay_type = item.pay_type;
      this.active = index;
    },

    pay_btn() {
      if (this.pay_type == "Paypal") {
        // Paypal
        this.paypalPay(this.order_sn);
      } else if (this.pay_type == "Yappy") {
        // Yappy
        this.yaPayPay(this.order_sn);
      } else {
        //余额
        this.yuePay(this.order_sn);
      }
    },

    // 获取会员信息
    getUserInfo() {
      this.$http.userInfo().then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
          console.log(this.userInfo,'this.userInfo');
          this.contacts =  this.userInfo.user_nickname
          this.contactsPhone = this.userInfo.mobile
          this.email = this.userInfo.mail
          this.WhatsApp = this.userInfo.whats_app
          this.wechat = this.userInfo.wechat
          this.vip = this.userInfo.vip;
          this.information_config = this.userInfo.information_config * 1;
        }
      });
    },

    // 获取详情
    getInformationDetail() {
      this.$http
        .informationDetail({
          information_id: this.information_id,
        })
        .then((res) => {
          if (res.code == 1) {
            console.log(res.data);
            let info = res.data;
            this.title = info.title;
            this.cate_name = [info.parent_cate_id, info.cate_id];
            // this.cascaderChangeClick(this.cate_name, 1);
            this.area_name = [info.province_id, info.addr_id, info.area_id];
            this.address = info.address;
            this.detail = info.detail;
            this.videoUrl = info.video;
            this.thumb = info.thumb;
            this.contacts = info.name;
            this.contactsPhone = info.mobile;
            this.email = info.email;
            this.WhatsApp = info.whatsapp;
            this.wechat = info.wx;
            this.tax_money = info.tax_money;
            this.duration = info.duration;

            // 基本信息
            this.getEditInformationDetail();
          }
        });
    },

    // 获取二级分类
    getInforType() {
      this.$http
        .getCateType({
          cate_id: this.cate_id,
          user_id: this.user_id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.inforData = res.data.data;
            this.pid = res.data.pid;
            this.duration = res.data.duration;
            this.price = res.data.price;
            this.pay_tax = res.data.pay_tax;
            this.total_money = res.data.total_money;
            this.tax_money = res.data.tax_money;
            console.log(this.inforData, "this.inforData");
            this.inforData = this.inforData.map((item) => {
              console.log("当前的item", item.type);
              return {
                ...item,
                // value: "",
                // valueId: item.type_id,
              };
            });
            console.log(this.inforData, 11111);
            this.isHasAddress = this.inforData.find((item) => item.type == 2);
            console.log(this.inforData);
            console.log("哈哈哈哈", this.isHasAddress);
            this.isHasAddress = this.isHasAddress ? true : false;

            console.log(this.infoData, 12345);
            this.inforData = this.inforData.filter((item) => item.type != 2);

            if (this.information_id && this.end_status != 2) {
              this.showDis = true;
              this.price = 0;
            }

            this.JWMoney = res.data.tow;
            this.noJWMoney = res.data.one;

            // 发布信息时，联系人、电话、whatsApp、微信、邮箱不需要重复填写
            // this.inforData.forEach(item => {
            // 	if (item.sign == 'Teléfono' || item.sign == 'Celular') {
            // 		item.value = this.userInfo.mobile;
            // 	}
            // 	if (item.sign == 'WhatsApp Logo') {
            // 		item.value = this.userInfo.whatsApp;
            // 	}
            // 	if (item.sign == 'WeChat') {
            // 		item.value = this.userInfo.wechat;
            // 	}
            // 	if (item.sign == 'Contacto') {
            // 		item.value = this.userInfo.contacts;
            // 	}
            // 	// if (item.sign == 'WeChat') {
            // 	// 	item.value = this.userInfo.user_email;
            // 	// }
            // })
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    //获取省市区
    getCountry() {
      this.$http.getCountry().then((res) => {
        if (res.code == 1) {
          this.countryList = res.data;
          console.log(this.countryList);
          let newCountryList;
          newCountryList = this.countryList.map((item) => {
            return {
              label: item.name,
              value: item.id,
              children: item.children.map((item2) => {
                return {
                  label: item2.name,
                  value: item2.id,
                  children: item2.children.map((item3) => {
                    return {
                      label: item3.name,
                      value: item3.id,
                    };
                  }),
                };
              }),
            };
          });
          this.countryList = JSON.parse(JSON.stringify(newCountryList));
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 获取所有分类
    getCategoryList() {
      this.$http
        .getCate({
          is_hot: 0,
        })
        .then((res) => {
          if (res.code == 1) {
            this.classifyList = res.data;
            // console.log(this.classifyList, 111111);
            let newClassifyList = this.classifyList.map((item) => {
              return {
                label: item.name,
                value: item.id,
                children: item.children.map((item2) => {
                  return {
                    label: item2.name,
                    value: item2.id,
                  };
                }),
              };
            });
            this.classifyList = JSON.parse(JSON.stringify(newClassifyList));
            // console.log(this.classifyList, 1234);
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    cascaderChangeClick(val, type) {
      switch (type * 1) {
        case 1:
          console.log(val, "leibie");
          this.parent_cate_id = val[0];
          this.cate_id = val.length ? val[1] : "";
          this.getInforType();
        case 2:
          // if (val.length > 0) {
          // 	this.province_id = val[0]; //省id
          // 	this.addr_id = val[1]; //市id
          // 	this.area_id = val[2]; //分类id，
          // } else {
          // 	this.province_id = '';
          // 	this.addr_id = '';
          // 	this.area_id = '';
          // }
          break;
      }
    },

    // 获取巴拿马协议
    getAll() {
      this.$http
        .getArticle({
          category_id: this.lang == "zh" ? 32 : 33,
        })
        .then((res) => {
          if (res.code == 1) {
            this.content = res.data.content;
          } else {
            this.$message.info(res.msg);
          }
        });
    },
    // 图片删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.previewImgDialog = true;
    },
    // 上传之前
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      console.log("文件类型", fileType);

      if (
        fileType === ".jpg" ||
        fileType === ".png" ||
        fileType === ".jpeg" ||
        fileType === ".bmp" ||
        fileType === ".gif"
      ) {
      } else {
        this.$message.error(this.$t("pleaseUploadTheCorrectImageType"));
        return false;
      }
    },

    // 上传之前
    beforeUpload1(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."));
      console.log("文件类型", fileType);

      if (
        fileType === ".jpg" ||
        fileType === ".png" ||
        fileType === ".jpeg" ||
        fileType === ".bmp" ||
        fileType === ".gif"
      ) {
      } else {
        this.$message.error(this.$t("pleaseUploadTheCorrectImageType"));
        return false;
      }
    },

    // 上传图片成功
    onChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.img_list = fileList;
    },
    //
    beforeUpload2(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        this.$message.error(this.$t("pleaseUploadTheCorrectVideoFormat"));
        return false;
      }
      if (!isLt10M) {
        this.$message.error(this.$t("theSizeOfUploadedVideoCannotExceed"));
        return false;
      }
    },
    onSuccess(res, file) {
      this.videoUrl = res.data[0].path;
      this.videoId = res.data[0].id;
      this.videoVisible = false;
    },
    progressing(res) {
      this.videoVisible = true;
      this.percent = Number(res.percent.toFixed());
      console.log(this.percent);
    },

    // 清除之前上传的视频
    removeVideo() {
      this.videoUrl = "";
      this.videoId = "";
      this.$refs.uploadVideo.clearFiles();
    },
    // 获取当前位置
    getAddressEvent(e) {
      console.log(e, "当前地址名字");
      this.address = e;
    },
    // 发布内容点击
    textareaClick() {
      this.$nextTick(() => {
        this.$refs.textareaRef.focus();
      });
    },

    // 立即发布
    toReleaseClick() {
      if (this.isFlag) return;
      if (this.loading == true) {
        this.$message.error(this.$t("other.repeatText"));
        return false;
      }
      if (!this.title) {
        this.$message.error(this.$t("tips.pleaseEnterTitle"));
        return;
      }

      //分类
      this.parent_cate_id = this.cate_name[0];
      this.cate_id = this.cate_name.length > 1 ? this.cate_name[1] : "";

      if (!this.cate_id) {
        this.$message.info(this.$t("tips.pleaseEnterSelectionCategory"));
        return;
      }

      // 省市区
      this.province_id = this.area_name[0];
      this.addr_id = this.area_name[1];
      this.area_id = this.area_name.length > 2 ? this.area_name[2] : "";

      if (!this.area_id) {
        this.$message.error(this.$t("release.selectCity"));
        return;
      }

      if (!this.detail) {
        this.$message.error(this.$t("tips.pleaseEnterDetailedDescription"));
        return;
      }

      let newImgList = this.img_list
        .map((item) => {
          if (item.response) {
            return item.response.data[0].id;
          } else {
            return item.id;
          }
        })
        .join(",");

      if (this.information_id && newImgList == "") {
        console.log(11112222);
        newImgList = this.previewFileList.map((item) => {
          return item.id;
        });
      }

      console.log(newImgList, "图片");

      if (newImgList.length == 0) {
        this.$message.error(this.$t("tips.atLeastOnePhoto"));
        return;
      }

      this.inforData.forEach((item) => {
        if (item.type == 2) {
          item.value = this.address;
        }
      });

      for (var i = 0; i < this.inforData.length; i++) {
        if (this.inforData[i].is_must == 1) {
          if (!this.inforData[i].value) {
            this.$message.error(
              this.inforData[i].type_name + '\u00a0' + this.$t("tips.cannotEmpty")
            );
            return;
          }
        }
      }

      console.log("下拉框的值", this.inforData);
      let newInforData = this.inforData.map((item) => {
        return item;
      });

      console.log("哈哈哈", newInforData);

      // if (!this.isRead) {
      //   this.$message.error(
      //     this.$t("tips.pleaseCheckThePanamaInformationReleaseAgreement")
      //   );
      //   return;
      // }

      if (!this.contacts) {
        this.$message.info("请填写联系人");
      }

      this.isFlag = true;
      console.log("啦啦啦啦啦");

      let data = {
        id: this.information_id,
        title: this.title, // 标题
        parent_cate_id: this.parent_cate_id, //父级分类的id
        cate_id: this.cate_id, //分类id
        detail: this.detail, //详情描述
        thumb: newImgList, // 图片
        video: this.videoId, // 视频
        province_id: this.province_id, //城市父id
        address: this.address, //详细位置
        addr_id: this.addr_id, //城市子id
        area_id: this.area_id, //第三级id
        information_data: JSON.stringify(newInforData),
        is_background: this.is_background ? 2 : 1, //位置凸显
        is_hot: this.is_hot ? 2 : 1, //位置推前
        payable_money:
          this.is_hot_status == 1
            ? this.noJWMoney.total_money
            : this.JWMoney.total_money, // 支付金额, // 支付金额
        name: this.contacts, // 联系人
        mobile: this.contactsPhone, //联系电话
        email: this.email, //邮件
        whatsapp: this.WhatsApp,
        wx: this.wechat,
        tax_money:
          this.is_hot_status == 1
            ? this.noJWMoney.tax_money
            : this.JWMoney.tax_money,
        day: this.duration, //发布天数
        lat: this.lat || "39.899",
        lng: this.lng || "116.39742",
        is_hot: this.is_hot_status,
      };

      console.log(data, "入参");

      if (this.information_id) {
        //编辑
        console.log("编辑");
        this.$http.editPublishInformation(data).then((res) => {
          if (res.code == 1) {
            this.isFlag = false;
            this.loading = false;
            this.releaseSuccessVisible = true;
            setTimeout(() => {
              this.$router.push({
                path: "/myRelease",
              });
              this.clearContent();
            }, 3000);
          } else if (res.code == 2) {
            this.isFlag = false;
            this.payVisible = true;
            this.order_sn = res.data.order_sn;
            // this.paypalPay(res.data.order_sn);
          } else {
            this.isFlag = false;
            this.$message.error(res.msg);
          }
        });
      } else {
        // 发布
        console.log("发布");
        this.$http.publishInformation(data).then((res) => {
          if (res.code == 1) {
            this.isFlag = false;
            this.loading = false;
            this.releaseSuccessVisible = true;
            setTimeout(() => {
              this.$router.push({
                path: "/myRelease",
              });
              this.clearContent();
            }, 3000);
          } else if (res.code == 2) {
            this.isFlag = false;
            this.payVisible = true;
            this.order_sn = res.data.order_sn;
            // this.paypalPay(res.data.order_sn);
          } else {
            this.isFlag = false;
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 选择支付方式
    // paypal支付
    paypalPay(orderSn) {
      this.$http
        .getPayPal({
          order_sn: orderSn,
        })
        .then((res) => {
          if (res.code == 1) {
            this.loading = false;
            this.payVisible = false;
            let payUrl = res.data;
            this.releaseSuccessVisible = true;
            console.log(payUrl, "11233415s");
            setTimeout(() => {
              this.$router.push({
                path: "/myRelease",
              });
              this.clearContent();
            }, 3000);
            window.open(payUrl);
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // yapay支付 646b400bad167
    yaPayPay(orderSn) {
      this.$http
        .getYaPay({
          order_sn: orderSn,
        })
        .then((res) => {
          if (res.code == 1) {
            this.loading = false;
            this.payVisible = false;
            let payUrl = res.data;
            this.releaseSuccessVisible = true;
            console.log(payUrl, "11233415s");
            setTimeout(() => {
              this.$router.push({
                path: "/myRelease",
              });
              this.clearContent();
            }, 3000);
            window.open(payUrl);
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 余额支付 5e489f45a051d
    yuePay(orderSn) {
      this.$http
        .getYuePay({
          order_sn: orderSn,
        })
        .then((res) => {
          if (res.code == 1) {
            this.loading = false;
            this.payVisible = false;
            this.releaseSuccessVisible = true;
            setTimeout(() => {
              this.$router.push({
                path: "/myRelease",
              });
              this.clearContent();
            }, 3000);
            // let payUrl = res.data;
            // console.log(payUrl, '11233415s');
            // window.open(payUrl);
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    // 所有内容清空
    clearContent() {
      this.title = "";
      this.cate_id = "";
      this.cate_name = "";
      this.detail = "";
      this.videoId = "";
      this.videoUrl = "";
      this.province_id = "";
      this.province_name = "";
      this.addr_id = "";
      this.add_name = "";
      this.area_id = "";
      this.area_name = "";
      this.img_list = [];
      this.inforData = [];
      this.$refs.uploadVideo.clearFiles();
      this.$refs.uploadImg.clearFiles();
      this.isRead = false;
      this.loading = false;
      this.isHasAddress = false;
      this.is_background = false; //位置凸显
      this.is_hot = false; //位置推前
    },

    // 获取编辑的内容
    getEditInformationDetail() {
      this.showDis = true;
      this.$http
        .editInformationDetail({
          information_id: this.information_id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.inforDetail = res.data;
            this.pid = this.inforDetail.parent_cate_id;

            console.log(this.pid, "this.pid");

            this.title = this.inforDetail.title;
            this.cate_name = [
              this.inforDetail.parent_cate_id,
              this.inforDetail.cate_id,
            ];
            this.cate_id = this.inforDetail.cate_id;
            this.detail = this.inforDetail.detail;

            this.previewFileList = this.inforDetail.thumb_url.map((item) => {
              return {
                id: item.id,
                url: item.path,
              };
            });


            let newVideoUrl = this.inforDetail.video_url;
            if (newVideoUrl) {
              this.videoUrl = newVideoUrl[0].path;
              this.videoId = newVideoUrl[0].id;
            }

            this.province_id = this.inforDetail.province_id;
            this.province_name = this.inforDetail.province_name; //城市父名字
            this.addr_id = this.inforDetail.addr_id; //城市子id
            this.addr_name = this.inforDetail.addr_name;
            this.area_name = [
              this.inforDetail.province_id,
              this.inforDetail.addr_id,
              this.inforDetail.area_id,
            ];
console.log(this.inforDetail.information_sku,'this.inforDetail.information_sku');
            if (this.pid == 159) {
              this.inforData = this.inforDetail.information_sku.map((item) => {
                return {
                  ...item
                  // ...item,
                  // value: item.value_id == 0 ? item.value : item.value_id,
                  // valueId: item.value_id,
                };
              });
            } else {
              this.inforData = this.inforDetail.sku_info.map((item) => {
                return {
                  ...item,
                  value: item.value_id == 0 ? item.value : item.value_id,
                  valueId: item.value_id,
                };
              });
            }

            this.inforData.forEach((item, index) => {
              if (index > 0) {
                if (item[0].value != "") {
                  this.ModelNum = index;
                }
              }
            });

            console.log(this.inforData,'this.inforData');



            console.log(this.inforData, "inforData");

            this.addressItem = this.inforData.find((item) => item.type == 2);
            this.isHasAddress = this.addressItem ? true : false;
            // console.log("嘿嘿嘿", this.isHasAddress);
            // setTimeout(() => {
            this.address = this.addressItem.value;
            console.log("当前地址的", this.address);
            // }, 1500)
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addMore() {
      if (this.ModelNum < 4) {
        this.ModelNum++;
        console.log(this.ModelNum);
      }
    },
    delMore() {
      if (this.ModelNum > 1) {
        this.ModelNum--;
        console.log(this.ModelNum);
      }
    },
  },
};
</script>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #f39800;
  border-color: #f39800;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f39800;
}
</style>
<style scoped="" lang="less">
.inline {
  display: inline-block;
}

.page {
  background: #f6f7f9;
}

.release {
  margin: 0 auto;
  width: 100%;
  padding: 32px 32px 64px;
  box-sizing: border-box;
}

.login_top {
  display: flex;

  .img {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .login_title {
    margin-left: 8px;
    font-weight: bold;
    font-size: 20px;
  }
}

.release_input {
  margin-top: 24px;
  background-color: #ffffff;
  text-align: left;
  padding: 24px 32px;

  .login_title_info {
    margin-left: 8px;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    .btn {
      background-color: #f39800;
      border: none;
      color: #fff;
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }

  .release_input_top {
    display: flex;

    .release_input_left {
      width: 50%;
      margin-right: 80px;

      .login_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 38px;
      }
    }

    .release_input_right {
      width: 50%;

      .login_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 38px;
      }
    }
  }

  .input_item {
    margin-bottom: 24px;

    .input_title {
      font-size: 16px;
      color: #333333;
      margin-bottom: 16px;
    }

    .input_content {
      width: 856px;
      height: 48px;
      display: inline-block;
      // padding: 8px 0px;
      border-radius: 4px;

      input {
        border: none;
        outline: none;
      }
    }

    .input_textarea {
      width: 856px;
      background: #f6f7f9;
      opacity: 1;
      border-radius: 4px;
      min-height: 200px;
      padding: 16px;
      box-sizing: border-box;

      /deep/ .el-textarea {
        textarea {
          outline: none;
          background: #f6f7f9;
          border: none;
          font-size: 14px;
          resize: none;
          font-family: Microsoft YaHei;
        }
      }
    }
  }
}

/deep/ .el-upload--picture-card {
  width: 140px;
  height: 140px;
}

/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ .el-upload__tip {
  width: 140px;
  text-align: center;
  white-space: nowrap;
}

.all_imgs {
  padding-bottom: 36px;
  // border-bottom: 1px dashed #DDDDDD;
  margin: 16px 0 24px;
}

.basic_infor {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 8px;

  .basic_item {
    width: 50%;
    // display: flex;
    align-items: center;
    margin-bottom: 32px;
    box-sizing: border-box;

    .basic_name {
      font-size: 16px;
      color: #333333;
      margin-right: 24px;
      width: 90px;
      white-space: nowrap;
    }

    .basic_input {
      width: 400px;
      padding: 16px 0 0 0;
      border-radius: 4px;

      input {
        border: none;
        outline: none;
      }
    }
  }
}

.release_bottom {
  margin-top: 80px;

  .release_btn {
    width: 856px;
    height: 48px;
    background: #fff;
    border: 1px solid #f39800;
    border-radius: 4px;
    margin: 0 auto;
    text-align: center;
    // line-height: 48px;
    font-size: 16px;
    color: #f39800;
    font-weight: bold;

    .total {
      font-size: 12px;
    }
  }

  .release_btn:hover {
    cursor: pointer;
  }

  .release_tip {
    width: 856px;
    margin-top: 32px;
    font-size: 12px;
    display: flex;
    justify-content: center;

    .img {
      display: flex;
      align-items: center;
      margin-right: 12px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    span {
      color: #4177dd;
    }

    span:hover {
      cursor: pointer;
    }
  }
}

.title_all {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

  .title_img {
    margin-right: 8px;

    img {
      display: flex;
      align-items: center;
      width: 24px;
      height: 24px;
    }
  }

  .content {
    font-size: 14px;
  }
}

/deep/ .el-dialog__body {
  max-height: 436px;
  overflow-y: scroll;
}

.success_dialog {
  /deep/ .el-dialog__body {
    max-height: 436px;
    overflow-y: hidden;
  }
}

.pay_dialog {
  /deep/ .el-dialog {
    width: 480px !important;
  }

  /deep/ .el-dialog__body {
    max-height: 480px;
    overflow-y: hidden;
  }

  /deep/ .el-dialog__header {
    text-align: left;
    padding: 24px 32px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid #dddddd;
  }

  .pay_mongy {
    height: 142px;
    border-bottom: 1px solid #dddddd;

    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    .money {
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #e60012;
    }

    .shui {
      margin-top: 12px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }

  .change_pay {
    border-bottom: 1px solid #dddddd;

    .pay_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;

      .left {
        display: flex;
        justify-content: left;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
        }

        div {
          margin-left: 20px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }

      .right {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .pay_box:last-child {
      margin-bottom: 32px;
    }
  }

  .btn_pay {
    padding-top: 24px;

    button {
      width: 300px;
      height: 40px;
      background: rgba(243, 152, 0);
      border-radius: 4px;
      border: none;
      color: #fff;
      font-size: 14px;
    }
  }
}

.release_success {
  padding-top: 56px;
  height: 416px;
  box-sizing: border-box;

  .success_img {
    img {
      width: 88px;
      height: 88px;
    }
  }

  .success_title {
    margin: 32px 0;
    font-weight: bold;
    color: #333333;
    font-size: 20px;
  }

  .success_tip {
    margin: 0 auto;
    color: #666666;
    width: 404px;
    word-break: break-all;
  }
}

/deep/ .basic_infor .el-select,
.el-input {
  width: 250px;
}

.upload_video {
  position: relative;
  width: 250px;
  height: 140px;
  background-color: #000000;

  .video {
    width: 250px;
    height: 140px;
  }

  .el-icon-remove {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
    color: #f39800;
  }
}

.basic_item_left {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.basic_item_right {
  margin-left: 24px;

  .img {
    image {
      width: 14px;
      height: 14px;
    }
  }
}

.tip {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	// margin: 32px 0 0 50px;
  margin-bottom: 20px;
	width: 830px;
	height: 48px;
	// background: rgba(230, 0, 18, 0.09);
	font-size: 14px;
	padding-left: 40px;
	text-align: left;
	// color: #F39800;
	// background-image: url('../../assets/mycenter/zhaopin_back.png');
	background: repeating-linear-gradient(to right, rgba(45, 145, 255, 0.1), rgba(45, 145, 255, 0));


	img {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}
}
</style>
